import text from '../text'

const setItem = (key, value) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem(key, value)
  } else {
    console.warn(`${text.LOGS} utils / localStorage undefined for setItem`)
  }
}

const getItem = (key) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage.getItem(key)
  }

  console.warn(`${text.LOGS} utils / localStorage undefined for getItem`)
  return ''
}

const removeItem = (key) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.removeItem(key)
  } else {
    console.warn(`${text.LOGS} utils / localStorage undefined for removeItem`)
  }
}

const localStorageSafe = {
  setItem,
  getItem,
  removeItem,
}

export default localStorageSafe
