import CONSTANTS from '../constants'

const { IS_DISCONNECTED } = CONSTANTS

function getConfiguration() {
  const date = new Date()
  const expirationDate = new Date(
    date.getFullYear() + 10, date.getMonth(), date.getDay(),
  ).toUTCString()

  let domain = ''
  if (typeof window !== 'undefined') {
    if (window.location.host.includes('.seloger.com')) {
      domain += 'domain=.seloger.com;'
    } else if (window.location.host.includes('.dignp.com')) {
      domain += 'domain=.dignp.com;'
    }
  }

  return `expires=${expirationDate};path=/;${domain}`
}

const getCookie = (name) => {
  const cookie = {}
  const decodedCookie = typeof document !== 'undefined' ? decodeURIComponent(document.cookie) : ''
  decodedCookie.split(';').forEach((el) => {
    const [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name] || ''
}

const setCookie = (name, token) => {
  if (typeof document === 'undefined') return

  const defaultConfiguration = getConfiguration()
  document.cookie = `${name}=${token};${defaultConfiguration}`
}

const deleteCookie = (name) => {
  if (typeof document === 'undefined') return

  const defaultConfiguration = getConfiguration()
  document.cookie = `${name}=${IS_DISCONNECTED};${defaultConfiguration}`
}

const handleCookies = {
  getCookie,
  setCookie,
  deleteCookie,
}

export default handleCookies
