import localStorageSafe from './../utils/localStorageSafe'
import handleCookies from './../utils/handleCookies'
import CONSTANTS from '../constants'

const { IS_DISCONNECTED, TOKEN } = CONSTANTS

const setToken = (token) => {
  handleCookies.setCookie(TOKEN, token)
  localStorageSafe.setItem(TOKEN, token)
}

const getToken = () => {
  const cookie = handleCookies.getCookie(TOKEN)

  // cookie has to be evaluated first before the localStorage because localStorage
  // is not cross domain with PA Unit
  return (cookie === IS_DISCONNECTED) ? '' : cookie || localStorageSafe.getItem(TOKEN)
}

const isConnected = () => (
  !!getToken()
)

const removeToken = () => {
  localStorageSafe.removeItem(TOKEN)
  handleCookies.deleteCookie(TOKEN)
}

(() => {
  if (typeof window !== 'undefined' && window.thor) {
    window.thor.pushVariable({
      user: {
        logged: isConnected() ? 1 : 0,
      },
    })
  }
})()

const authentication = {
  isConnected,
  getToken,
  setToken,
  removeToken,
}

export default authentication
